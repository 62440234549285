/** @jsx jsx */

import { FC, ReactElement } from 'react'
import { Box, Flex, jsx } from 'theme-ui'

interface Quote {
  author: string | ReactElement
  title?: string
  quote: string
}

interface QuotesProps {
  containerStyles?: {}
  quotes: Quote[]
}

const Quotes: FC<QuotesProps> = ({ containerStyles, quotes }) => {
  const { quote, author, title } = quotes[0]

  function goldmanSachs() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x={0}
        y={0}
        width={48}
        height={48}
        viewBox="0 0 73 73"
        sx={{ maxWidth: `100%`, display: `block`, color: `goldmanSachsLogoPrimary` }}
      >
        <title>Goldman Sachs logo</title>
        <rect x={0.4} y={0.4} width={72} height={72} sx={{ fill: `currentColor` }} />

        <g sx={{ color: `goldmanSachsLogoSecondary` }}>
          <path
            d="M9.1,10.6H13v6.3c-1.3,0.2-2.7,0.4-3.6,0.4c-4,0-5.6-2.4-5.6-6.4c0-4.3,2.1-6.7,6.2-6.7c0.5,0,1.6,0,2.9,0.3
		v3.9H12l-0.5-1.5c-0.5-1.4-1.1-2-2-2c-1.7,0-2.5,2.1-2.5,5.9c0,3.9,0.5,5.7,2.5,5.7c0.2,0,0.5,0,1-0.1v-4.4L9,11L9.1,10.6
		L9.1,10.6z M12.9,27.6c0,2.5-1.7,4-4.8,4c-0.9,0-2.1-0.1-3.2-0.4v-4.8h0.9l0.4,1.1c0.8,2.5,1.5,3.4,2.5,3.4c1.1,0,1.9-0.9,1.9-2.1
		c0-1-0.5-1.7-1.6-2.3l-1.9-1c-1.5-0.8-2.2-2-2.2-3.4c0-2.2,1.8-3.5,4.8-3.5c0.7,0,1.5,0,2.7,0.2v4h-0.9l-0.5-1.5c-0.5-1.5-1-2-2-2
		s-1.7,0.6-1.7,1.5c0,0.8,0.5,1.5,1.5,2l1.9,1C12.1,24.8,12.9,26,12.9,27.6z M21.2,30.6l0.4,0.5v0.3h-2.9l-0.1-1.2
		c-0.3,0.9-1.1,1.4-2.2,1.4c-1.5,0-2.4-0.9-2.4-2.5c0-1.5,0.9-2.5,3.5-3.6l1.1-0.5v-1.3c0-1-0.3-1.5-1-1.5c-0.6,0-1,0.4-1.5,1.5
		l-0.6,1.5h-0.9v-2.9c1.1-0.3,2.2-0.5,3.2-0.5c2.2,0,3.3,1,3.3,2.9L21.2,30.6L21.2,30.6z M22.4,12.2c0,3.2-1.7,5-3.9,5
		s-3.9-1.8-3.9-5s1.7-5,3.9-5C20.7,7.2,22.4,9,22.4,12.2z M18.6,28.9v-3.1L18.3,26c-1.1,0.5-1.6,1.4-1.6,2.8c0,1.2,0.3,1.7,1,1.7
		C18.3,30.5,18.6,29.8,18.6,28.9z M19.7,12.2c0-3.5-0.3-4.4-1.2-4.4c-0.9,0-1.2,0.9-1.2,4.4s0.3,4.4,1.2,4.4
		C19.4,16.6,19.7,15.7,19.7,12.2z M28.9,29l0.5,0.3c-0.6,1.6-1.4,2.3-3,2.3c-2.4,0-3.7-1.9-3.7-4.9c0-3.3,1.5-5.1,4.1-5.1
		c0.7,0,1.7,0.1,2.5,0.4v3.2h-0.9L28.2,24c-0.5-1.4-0.8-1.8-1.3-1.8c-0.8,0-1.4,1-1.4,4.5c0,2.9,0.4,3.9,1.6,3.9
		C27.8,30.7,28.3,30.3,28.9,29z M27,17h-3.4v-0.3l0.4-0.5V5.7l-0.4-0.5V4.9l3-0.2v11.5l0.4,0.5C27,16.7,27,17,27,17z M31.7,17.2
		c-2.1,0-3.4-1.8-3.4-5.1s1.3-4.9,3.4-4.9c1,0,1.5,0.4,1.8,1.2V5.7l-0.4-0.5V4.9l3-0.2v11.5l0.4,0.5V17h-2.6l-0.2-1.4
		C33.4,16.7,32.8,17.2,31.7,17.2z M38.2,30.6l0.4,0.5v0.3h-3.4v-0.3l0.4-0.5v-6.8c0-0.7-0.3-1.2-0.9-1.2c-0.7,0-1.2,0.6-1.2,1.6
		v6.3l0.4,0.5v0.3h-3.4v-0.3l0.4-0.5V20.1l-0.4-0.5v-0.3l3-0.2v3.8c0.4-0.9,1.3-1.3,2.2-1.3c1.6,0,2.5,1,2.5,2.9L38.2,30.6
		L38.2,30.6z M31.1,12.1c0,3.3,0.3,4.3,1.2,4.3c1,0,1.2-1.4,1.2-4.4c0-2.9-0.2-4.2-1.2-4.2C31.4,7.9,31.1,8.9,31.1,12.1z
			M50.1,16.1l0.4,0.5V17h-3.4v-0.2l0.4-0.5V9.3c0-0.6-0.3-1.1-0.9-1.1c-0.7,0-1.1,0.7-1.1,1.7v6.3l0.4,0.5V17h-3.4v-0.3l0.4-0.5
		V9.3c0-0.6-0.3-1.1-0.8-1.1c-0.7,0-1.1,0.7-1.1,1.7v6.3l0.4,0.5V17h-3.4v-0.3l0.4-0.5V8.5L37.9,8V7.7l2.6-0.3h0.2l0.1,1.1
		c0.3-0.9,1.2-1.4,2.3-1.4c1.1,0,1.8,0.5,2.2,1.5c0.4-1,1.3-1.5,2.4-1.5c1.5,0,2.3,1,2.3,2.8L50.1,16.1L50.1,16.1z M46.5,28.6
		c0,1.7-1,3.1-3.6,3.1c-0.6,0-1.5-0.1-2.9-0.3v-3.5h0.8l0.3,0.7c0.6,1.9,1.1,2.4,2.1,2.4c1,0,1.5-0.5,1.5-1.5
		c0-0.8-0.4-1.3-1.3-1.8l-1.7-0.9c-1.1-0.6-1.6-1.4-1.6-2.5c0-1.8,1.3-2.8,3.5-2.8c0.7,0,1.5,0.1,2.4,0.3v2.7h-0.8l-0.4-1
		c-0.4-1-0.8-1.4-1.6-1.4c-0.8,0-1.3,0.4-1.3,1.1c0,0.6,0.4,1,1.3,1.5l1.7,0.9C45.9,26.4,46.5,27.3,46.5,28.6z M58.8,16.1l0.4,0.5
		V17h-2.9l-0.1-1.1c-0.3,0.9-1.2,1.4-2.2,1.4c-1.5,0-2.4-0.9-2.4-2.5c0-1.5,0.9-2.5,3.5-3.6l1.2-0.5V9.3c0-1-0.3-1.5-1-1.5
		c-0.6,0-1,0.4-1.5,1.5l-0.6,1.5h-0.9v-3c1.1-0.3,2.2-0.5,3.2-0.5c2.2,0,3.4,1,3.4,2.9L58.8,16.1L58.8,16.1z M56.2,14.5v-3.1
		l-0.4,0.2c-1.1,0.5-1.6,1.4-1.6,2.8c0,1.1,0.3,1.7,1,1.7C55.8,16,56.2,15.4,56.2,14.5z M68.5,16.7V17h-3.4v-0.3l0.4-0.5V9.4
		c0-0.7-0.3-1.1-0.9-1.1c-0.7,0-1.2,0.6-1.2,1.6v6.3l0.4,0.5V17h-3.4v-0.3l0.4-0.5V8.5L60.4,8V7.7l2.6-0.3h0.2l0.1,1.1
		c0.3-1,1.3-1.4,2.3-1.4c1.6,0,2.5,1,2.5,2.9v6.1L68.5,16.7z"
            sx={{ fill: `goldmanSachsLogoSecondary` }}
          />
        </g>
      </svg>
    )
  }

  return (
    <Flex sx={{ ...containerStyles }}>
      <Flex key={quote} sx={{ flex: 1, justifyContent: `space-around`, bg: `quoteUnderlay` }}>
        <Box sx={{ maxWidth: `72.5ch`, py: 3, px: 4 }}>
          <blockquote
            sx={{
              mt: 0,
              mx: 0,
              fontSize: 3,
              textIndent: `-0.65rem`,
              color: `text`,
            }}
          >
            <span sx={{ color: `gray6_slate3` }}>“</span>
            {quote}
            <span sx={{ color: `gray6_slate3` }}>”</span>
            <sup sx={{ ml: 1, fontSize: 1, color: `gray6_slate3` }}>2</sup>
          </blockquote>

          <Flex sx={{ mb: 4, flexDirection: [`column-reverse`, `row`], alignItems: [`flex-start`, `center`] }}>
            <div sx={{ size: [48], display: `block`, flex: `48px 0 0` }}>{goldmanSachs()}</div>
            <div sx={{ mb: [3, 0], pl: [0, 3] }}>
              <p sx={{ my: 0, fontWeight: 550, fontSize: [2, 3], lineHeight: 1.25, color: `gray6_c1cdda` }}>{author}</p>
              <p sx={{ my: 0, fontSize: [1, 2], lineHeight: 1.25, color: `gray6_c1cdda` }}>{title}</p>
            </div>
          </Flex>

          <p sx={{ my: 0, position: `relative`, lineHeight: 1.125, color: `gray5_slate4` }}>
            <small sx={{ fontSize: 0 }}>
              <sup sx={{ fontSize: `10px`, position: `absolute`, left: `-0.65rem` }}>2</sup>Testimonial may not be
              representative of the experience of all customers <br sx={{ display: [`none`, `block`] }} />
              and is not a guarantee of future performance or success.
            </small>
          </p>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Quotes
