/** @jsx jsx */

import { FC, ReactElement } from 'react'
import { Flex, jsx, useColorMode } from 'theme-ui'

import X from './x'

interface HeroProps {
  preTitle: string | ReactElement
  title: string | ReactElement
  description: string | ReactElement
}

const Hero: FC<HeroProps> = ({ preTitle, title, description }) => {
  const [colorMode] = useColorMode()

  function primary() {
    if (colorMode === `dark`) return `black`
    if (colorMode === `light`) return `#f7f7f7`
    if (typeof window !== `undefined` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`).matches)
      return `black`
    if (
      typeof window !== `undefined` &&
      window.matchMedia &&
      window.matchMedia(`(prefers-color-scheme: light)`).matches
    )
      return `#f7f7f7`

    return `transparent`
  }

  function secondary() {
    if (colorMode === `dark`) return `rgba(0, 0, 0, 0.01)`
    if (colorMode === `light`) return `rgba(255, 255, 255, 0.01)`
    if (typeof window !== `undefined` && window.matchMedia && window.matchMedia(`(prefers-color-scheme: dark)`).matches)
      return `rgba(0, 0, 0, 0.01)`
    if (
      typeof window !== `undefined` &&
      window.matchMedia &&
      window.matchMedia(`(prefers-color-scheme: light)`).matches
    )
      return `rgba(255, 255, 255, 0.01)`

    return `transparent`
  }

  return (
    <Flex
      as="section"
      sx={{
        pb: `80px`,
        position: `relative`,
        minHeight: `calc(100vh - 5rem)`,
        justifyContent: `center`,
        alignItems: `center`,
        overflow: `hidden`,
        perspective: `40vh`,
        color: `black_white`,
        bg: `gray0_black`,
      }}
    >
      {/* Grid */}
      <div
        sx={{
          width: `200%`,
          height: `calc(100% / 2)`,
          position: `absolute`,
          bottom: 0,
          left: `-50%`,
          zIndex: `-1 !important`,
          transform: `rotateX(90deg) translateZ(-1px)`,
          transformStyle: `preserve-3d`,
          opacity: 0,
          animation: `3s fade-in linear forwards`,

          [`@media (prefers-reduced-motion)`]: {
            opacity: 1,
            animation: `none`,
          },

          backgroundImage: `
            linear-gradient(${primary()}, ${secondary()}),
            repeating-linear-gradient(#2f71e375, #2f71e375 1px, ${secondary()} 1px, ${secondary()} 10px),
            repeating-linear-gradient(90deg, #41d4c750, #41d4c750 1px, ${secondary()} 1px, ${secondary()} 20px)
          `,
        }}
      />

      {/* Content */}
      <div
        sx={{ width: `100%`, position: `relative`, top: [`-40px`, 0], textAlign: `center`, transform: `translateZ(0)` }}
      >
        {/* X */}
        <Flex
          sx={{
            position: `absolute`,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            justifyContent: [`center`],
            alignItems: `center`,
          }}
        >
          <X dimmed />
          <X />
        </Flex>

        {/* Pretitle */}
        <h1
          sx={{
            my: 0,
            px: [4, 0],
            fontSize: `8vmin`,
            lineHeight: 1.05,
            opacity: 0,

            animation: `
                0.5s 1s fade-in linear forwards,
                5s 0.5s slide-up-pre-title ease-in-out,
                0.5s 4s fade-out linear forwards
              `,

            [`@media (prefers-reduced-motion)`]: {
              animation: `
                0.5s 1s fade-in linear forwards,
                0.5s 4s fade-out linear forwards
              `,
            },
          }}
        >
          {preTitle}
        </h1>

        {/* Title and description */}
        <div
          sx={{
            position: `absolute`,
            top: `calc(50% - 12.5vmin)`,
            left: 0,
            right: 0,
            opacity: 0,

            animation: `
                0.5s 4.25s fade-in ease-in-out forwards,
                4s 3.75s slide-up-title ease-in-out forwards
              `,

            [`@media (prefers-reduced-motion)`]: {
              animation: `0.5s 4.25s fade-in ease-in-out forwards`,
            },
          }}
        >
          <h1 sx={{ my: 0, fontSize: [`9vmin`], lineHeight: 1.05 }}>{title}</h1>

          <p
            sx={{
              mt: 32,
              mb: 0,
              px: 3,
              fontWeight: 525,
              fontSize: `3.25vmin`,
              lineHeight: 1.325,
              color: `gray5_slate4`,

              [`& > a`]: {
                textDecoration: `none`,
                color: `inherit`,
              },

              [`& > a:hover, & > a:focus`]: {
                textDecoration: `underline`,
              },
            }}
          >
            {description}
          </p>
        </div>
      </div>
    </Flex>
  )
}

export default Hero
