/** @jsx jsx */

import { graphql } from 'gatsby'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import grid1e from '../assets/backgrounds/grid-1e.svg'
import gridMix2b from '../assets/backgrounds/grid-mix2b.svg'
import BulletPoints from '../components/bullet-points'
import Halo from '../components/halo'
import Hero from '../components/hero'
import HorizontalDivider from '../components/horizontal-divider'
import Layout from '../components/layout'
import Quotes from '../components/quotes'
import SectionWithCards from '../components/section-with-cards'
import SignUpCallToAction from '../components/sign-up-call-to-action'
import Video, { VideoProps } from '../components/video'
import { cartaXFor, halo, hero, poweredBy, quotes, whyCartaX } from '../content/home'

interface HomeProps {
  data: {
    video: {
      caption: VideoProps['caption']

      file: {
        src: VideoProps['src']
      }
    }

    videoPoster: {
      file: {
        poster: VideoProps['poster']
      }
    }
  }

  location: {
    pathname: string
  }
}

const Home: FC<HomeProps> = ({ data, location }) => {
  const { caption } = data.video
  const { poster } = data.videoPoster.file
  const { src } = data.video.file

  const cartaXForContainerStyles = {
    py: 4,
    color: `black_white`,
    bg: `white_0b1218`,
    backgroundImage: [`none`, null, null, `url(${grid1e})`],
    backgroundPosition: `calc(100% + 480px) center`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `2405.35px 918.35px`,
  }

  const whyCartaXContainerStyles = {
    pt: 5,
    pb: 4,
    color: `black_white`,
    bg: `gray0_slate9`,
    backgroundImage: [`none`, null, null, `url(${gridMix2b})`],
    backgroundPosition: `-677px calc(100% + 653px)`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `2551.35px 1146.35px`,
  }

  const quotesContainerStyles = {
    pt: [5, null, `96px`],
    pb: 5,
    bg: `white_0b1218`,
    backgroundImage: [`none`, null, null, `url(${gridMix2b})`],
    backgroundPosition: `-675px -490px`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `2551.35px 1146.35px`,
  }

  const poweredByContainerStyles = { py: 4, color: `black_white`, bg: `gray0_slate9` }

  return (
    <Layout {...{ location }} includeVisual={false}>
      <Halo {...halo} />
      <Hero {...hero} />
      <HorizontalDivider />
      <SectionWithCards {...cartaXFor} containerStyles={cartaXForContainerStyles} />
      <Video {...{ caption, poster, src }} containerStyles={{ pt: 0 }} figureStyles={{ pt: 0 }} />
      <BulletPoints {...whyCartaX} {...{ location }} containerStyles={whyCartaXContainerStyles} />
      <Quotes {...quotes} containerStyles={quotesContainerStyles} />
      <SectionWithCards {...poweredBy} containerStyles={poweredByContainerStyles} />
      <SignUpCallToAction />
    </Layout>
  )
}

export default Home

export const pageQuery = graphql`
  query {
    video: contentfulAsset(file: { contentType: { eq: "video/mp4" } }, title: { eq: "Willy Schlacks" }) {
      caption: description

      file {
        src: url
      }
    }

    videoPoster: contentfulAsset(file: { contentType: { eq: "image/png" } }, title: { eq: "Willy Schlacks" }) {
      file {
        poster: url
      }
    }
  }
`
