/** @jsx jsx */

import { FC } from 'react'
import { jsx } from 'theme-ui'

interface XProps {
  dimmed?: boolean
}

const X: FC<XProps> = ({ dimmed }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 243.4 268.5"
      sx={{
        size: [`80vmin`, `60vmin`],
        display: `block`,
        position: dimmed ? `static` : `absolute`,
        animation: dimmed ? `none` : `2s 0.75s fade-out ease-out forwards`,
      }}
    >
      <g sx={{ color: dimmed ? `xDimmedPrimary` : `xPrimary` }}>
        <polygon sx={{ fill: `currentColor` }} points="154.7,130.2 121.7,182 176.9,268.5 243.4,268.5" />
        <polygon sx={{ fill: `currentColor` }} points="237.8,0 172,0 121.7,78.6 154.7,130.2" />
      </g>

      <g sx={{ color: dimmed ? `xDimmedSecondary` : `xSecondary` }}>
        <polygon
          sx={{ fill: `currentColor` }}
          points="121.7,78.6 71.4,0 5.6,0 88.6,130.2 0,268.5 66.5,268.5 121.7,182 154.7,130.2"
        />
      </g>

      <g sx={{ color: dimmed ? `xDimmedTertiary` : `xTertiary` }}>
        <polygon sx={{ fill: `currentColor`, opacity: 0.4 }} points="125.9,72 156.1,128.1 154.7,130.2 121.7,78.6" />
        <polygon sx={{ fill: `currentColor`, opacity: 0.4 }} points="121.7,182 132.9,199.6 156.1,132.2 154.7,130.2" />
      </g>
    </svg>
  )
}

export default X
