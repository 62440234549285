/** @jsx jsx */

import { Fragment } from 'react'
import { jsx } from 'theme-ui'

import arrowsIconPath from '../assets/icons/arrows.svg'
import chatIconPath from '../assets/icons/chat.svg'
import chevronIconPath from '../assets/icons/chevron.svg'
import docsIconPath from '../assets/icons/docs.svg'
import donutIconPath from '../assets/icons/donut.svg'
import kycIconPath from '../assets/icons/kyc.svg'
import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'

export const halo = {
  root: `./`,
}

export const hero = {
  preTitle: (
    <Fragment>
      One small step <br sx={{ display: [`block`, `none`] }} />
      for Carta
    </Fragment>
  ),
  title: (
    <Fragment>
      One giant leap <br />
      for private markets
    </Fragment>
  ),
  description: (
    <UniversalLink to={routes.caseStudiesEquipmentshareCartaCross}>
      Read: EquipmentShare Carta Cross case study
    </UniversalLink>
  ),
}

export const cartaXFor = {
  title: (
    <Fragment>
      CartaX: Tailored liquidity solutions<sup sx={{ ml: 1, fontSize: 2, color: `gray6_slate3` }}>1</sup>
    </Fragment>
  ),
  description: `From setup to settlement, CartaX powers the full spectrum of private company and private fund transactions.`,
  items: [
    {
      to: routes.privateCompanies,
      title: `Private companies`,
      description: `Choose the right transaction for your stakeholders, including secondary auctions and tender offers`,
    },
    {
      to: routes.institutionalInvestors,
      title: `Institutional investors`,
      description: `Access equity in emerging, innovative businesses and other private market opportunities with issuer-sourced disclosure`,
    },
  ],
  note: (
    <small sx={{ fontSize: 0 }}>
      <sup sx={{ fontSize: `10px`, position: `absolute`, left: `-0.65rem` }}>1</sup>Transactions are periodic in nature
      and there is no continuous liquid market for the securities. All securities are sold pursuant to an exemption from
      registration and remain restricted after purchasing. There is no guarantee of any future liquidity events or an
      issuer-IPO.
    </small>
  ),
}

export const whyCartaX = {
  title: `CartaX: More than a trading platform`,
  items: [
    {
      icon: donutIconPath,
      title: `Seamless cap table integration`,
      description: `Enable firm orders, set participation parameters, and provide efficient same-day settlement.`,
    },
    {
      icon: chevronIconPath,
      title: `Diverse network of institutional investors`,
      description: `Access investors across venture capital firms, direct secondary funds, hedge funds, family offices, investment banks, sovereign wealth funds, and money managers.`,
    },
    {
      icon: docsIconPath,
      title: `Regulatory framework`,
      description: `CCMX has implemented a comprehensive framework designed to achieve compliance with applicable rules and regulations.`,
    },
    {
      icon: kycIconPath,
      title: `Compliance first culture`,
      description: `All CartaX participants interact with the market through a CCMX brokerage account which requires ID/KYC verification and AML/OFAC screening.`,
    },
    {
      icon: arrowsIconPath,
      title: `Innovative technology platform`,
      description: `Get the benefits of scale, efficiency, and stability for multiple transaction models through our electronic execution and integrated settlement platform.`,
    },
    {
      icon: chatIconPath,
      title: `Bilateral transactions`,
      description: `By operating a market in your equity, CartaX eliminates the need for you to sponsor transactions, thus reducing issuer overhead and enabling tax efficiencies for participants.`,
    },
  ],
}

export const quotes = {
  quotes: [
    {
      author: `Darren Cohen`,
      title: `Goldman Sachs Merchant Banking Division`,
      quote: `Carta is led by a visionary leadership team with a proven track record for building scalable, innovative solutions that effectively address pain points in inefficient markets. We are thrilled to be partnering with Carta to help the company achieve its potential and develop sound market infrastructure that unlocks liquidity for all market participants.`,
    },
  ],
}

export const poweredBy = {
  title: `CartaX: Powered by technology & expertise`,
  description: (
    <Fragment>
      CartaX is integrated with its affiliates, who have managed over $1B in secondary volume to date. CCMX also
      leverages Carta’s network of investors, law firms, and accounting partners.
    </Fragment>
  ),
  items: [
    {
      href: routes.cartaCapTable,
      title: `Full Carta integration`,
      description: `The first vertically integrated market for private company and private fund transactions`,
      secondaryDescription: `Learn more about Carta’s cap table solutions`,
    },
    {
      href: routes.emailInfo,
      title: `Expert market operators`,
      description: `Built and run by experts with decades of combined industry expertise across financial services`,
      secondaryDescription: `Get in touch with our team`,
    },
    {
      to: routes.partnersLegal,
      title: `Network of advisors`,
      description: `Our Legal Advisory team saw an opportunity to help us shape the future of private markets`,
      secondaryDescription: `Learn more about our Legal Advisory team`,
    },
  ],
}
